import { FC } from 'react'
import { useGetEnvironmentQuery } from 'common/services/useEnvironment'

type EnvironmentReadyCheckerType = {
  match: {
    params: {
      environmentId?: string
    }
  }
}

const EnvironmentReadyChecker: FC<EnvironmentReadyCheckerType> = ({
  children,
  match,
}) => {
  const { data, isLoading } = useGetEnvironmentQuery(
    {
      id: match.params.environmentId,
    },
    { pollingInterval: 1000, skip: !match.params.environmentId },
  )
  if (!match?.params?.environmentId) {
    return children
  }
  return isLoading ? (
    <div className='text-center'>
      <Loader />
    </div>
  ) : (
    children
  )
}

export default EnvironmentReadyChecker
